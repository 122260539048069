import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context";
import { getDate, getLink } from "../../utils/helper";
import { parseJsonData } from "../helper";
import "./blog.scss";

const Blog = ({ story }) => {
  const {
    content: { Title, para1, para2, tag_list },
  } = story;
  const { data, methods } = useContext(GlobalContext);
  const { lang } = data;
  const { blogdetail, tags } = useStaticQuery(graphql`
    {
      blogdetail: allStoryblokEntry(
        filter: { field_component: { eq: "Blog_Detail" } }
        sort: { fields: field_Publish_Date_string, order: DESC }
      ) {
        edges {
          node {
            id
            full_slug
            content
            lang
            tag_list
          }
        }
      }
      tags: allStoryblokTag {
        edges {
          node {
            name
          }
        }
      }
    }
  `);

  const blogdata = blogdetail?.edges
    ?.filter((i) => i.node?.lang === lang)
    .map((item) => {
      const data = parseJsonData(item?.node?.content);
      const fullSlug = item.node.full_slug;
      const tag_list = item.node.tag_list;
      return { data, fullSlug, tag_list };
    });
  const initialItems = 9;
  const totalBlog = parseInt(blogdata?.length);
  const perItem = parseInt(Math.ceil(totalBlog / initialItems));
  const [currentTag, setCurrentTag] = useState("All");
  const [currentItems, setCurrentItems] = useState(initialItems);

  const BlogCard = (blog) => {
    const { Title, Thumbnail, Publish_Date, Category } = blog?.data;
    const date = Publish_Date?.split(" ")[0];
    return (
      <>
        <div className="blogCard">
          <Link to={getLink(blog?.fullSlug)}>
            <div className="bloglist-featured">
              <img src={Thumbnail?.filename} />
            </div>
            <div className="bloglist-info">
              <h5> {Title} </h5>
              <div className="bloglist-bottom">
                <p> {getDate(date, lang)} </p>
                <span> {Category} </span>
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="blogpage-section">
      <div className="container">
        <div className="bloglist-header">
          <div className="bloglist-header-left">
            <h1> {Title} </h1>
          </div>
          <div className="bloglist-header-right">
            <ul>
              <li onClick={() => setCurrentTag("All")}>
                <a className={currentTag === "All" ? "active" : ""}>All</a>
              </li>
              {Array.isArray(tags?.edges) &&
                tags.edges.map((tag) => (
                  <li onClick={() => setCurrentTag(tag?.node?.name)}>
                    <a
                      className={tag?.node?.name === currentTag ? "active" : ""}
                    >
                      {tag?.node?.name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="blogList-section">
          {Array.isArray(blogdata) &&
            (currentTag != "All"
              ? blogdata
                  ?.filter((i) =>
                    i?.tag_list?.find((item) => item?.toString() === currentTag)
                  )
                  ?.slice(0, currentItems)
                  ?.map((blg) => BlogCard(blg))
              : blogdata?.slice(0, currentItems)?.map((blg) => BlogCard(blg)))}
        </div>
        <div>
          {!(
            currentItems >=
            blogdata?.filter(
              (i) =>
                currentTag == "All" ||
                !!i?.tag_list?.find((item) => item?.toString() === currentTag)
            )?.length
          ) && (
            <div className="load-blogs">
              {" "}
              <button
                onClick={() =>
                  setCurrentItems(parseInt(currentItems + initialItems))
                }
              >
                {" "}
                Show More{" "}
              </button>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
