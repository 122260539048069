import React from "react"
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import Blog from "../components/Blog"

export default function BlogPage({ pageContext, location }) {
  const story = useStoryblokState(pageContext.story)
  const { content: { Breadcrumbs } } = story
  
  return (
    <Layout location={location} meta={{ ...story?.content?.Meta, tracking_variable: story?.content?.tracking_variable }} lang={story?.lang}  breadcrumbs={Breadcrumbs[0]?.crumbs} pathname={pageContext.pathname}>
        <Blog story={story} />
    </Layout>
  )
}
